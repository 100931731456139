import "./Header.scss";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

// bootstrap
import {
  Container,
  Stack,
  Form,
  Nav,
  Navbar,
  Badge,
  Dropdown,
  Button,
} from "react-bootstrap";

// confirmation Modal
import ConfirmationModal from "../ConfirmationPopupModal/ConfirmationModal";
import ProfileMenuModal from "../ProfileMenuModal/ProfileMenuModal";

import ProgramsDropDown from "../ProgramsDropDown/ProgramsDropDown";
import SearchBar from "../SearchBar/SearchBar";
//

import { BagFill, BellFill } from "react-bootstrap-icons";

// data
import localData from "data/data.json";
let pageData = localData.header;

export default function Header(props) {
  //  navigation
  const [modalShow, setModalShow] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // get the website location

  let onUserLogout = () => {
    setModalShow(false);
    navigate("/login");
    props.LogoutUser();
  };

  const { onProgramDetailsPage } = props;

  useEffect(() => {
    console.log("navvv onProgramDetailsPage ... ",onProgramDetailsPage);
  }, [onProgramDetailsPage]);

  return (
    <div
      className={`ts-header fixed-top ${
        props.isLogged ? "ts-user-logged" : ""
      }`}
      data-testid="Header"
    >
      {onProgramDetailsPage ? (
        <>
          <Navbar expand="xl">
            <Container>
              <Navbar.Brand>
                <Link to={props.isLogged ? "home" : "./"}>
                  <img
                    src={
                      props.rtlSwitch
                        ? "/assets/images/reham-logo-arabic-white.png"
                        : "/assets/images/reham-logo-english-white.png"
                    }
                    alt="Reham logo"
                  />
                </Link>
              </Navbar.Brand>
            </Container>
          </Navbar>
        </>
      ) : (
        <>
          <Navbar expand="xl">
            <Container>
              <Navbar.Brand>
                <Link to={props.isLogged ? "home" : "./"}>
                  <img
                    src={
                      props.rtlSwitch
                        ? "/assets/images/reham-logo-arabic-white.png"
                        : "/assets/images/reham-logo-english-white.png"
                    }
                    alt="Reham logo"
                  />
                </Link>
              </Navbar.Brand>

              <Navbar.Toggle>
                <span></span>
                <span></span>
                <span></span>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={props.rtlSwitch ? "ms-auto" : "me-auto"}>
                  <Stack className="px-3 flex-column flex-xl-row gap-xl-3">
                    {props.isLogged === true ? null : (
                      <ProgramsDropDown rtlSwitch={props.rtlSwitch} />
                    )}
                    <SearchBar rtlSwitch={props.rtlSwitch} />
                  </Stack>
                </Nav>
                <div className="d-flex flex-column flex-xl-row gap-3 gap-xl-2 gap-xxl-3 align-items-end align-items-lg-center ">
                  <div className="nav-item">
                    <Form
                      id="rtlSwitchForm"
                      className={props.rtlSwitch ? "ts-en-font" : "ts-ar-font"}
                    >
                      <Form.Check
                        type="switch"
                        id="rtlSwitch"
                        label={
                          props.rtlSwitch
                            ? pageData.language_switcher_en
                            : pageData.language_switcher_ar
                        }
                        onChange={props.rtlSwitchFunc}
                        defaultChecked={props.rtlSwitch}
                      />
                    </Form>
                  </div>
                  <div className="nav-item">
                    <Link className="ts-router-link " to="/cart">
                      <Button className="ts-header-icon btn-cart d-flex align-items-center gap-2">
                        <BagFill className="me-2" />
                        <span className="title">
                          {props.rtlSwitch
                            ? pageData.cart_ar
                            : pageData.cart_en}
                        </span>
                        {props.ProductNumber < 1 ? (
                          ""
                        ) : (
                          <Badge className="rounded-circle ">
                            {props.ProductNumber}
                          </Badge>
                        )}
                      </Button>
                    </Link>
                  </div>

                  {props.isLogged === true ? (
                    <>
                      <div className="nav-item">
                        <Link className="ts-router-link " to="/notification">
                          <Button className="ts-header-icon btn-bell ">
                            <BellFill />

                            {props.NotificationsNumber < 1 ? (
                              ""
                            ) : (
                              <Badge className="rounded-circle">
                                {props.NotificationsNumber}
                              </Badge>
                            )}
                          </Button>
                        </Link>
                      </div>

                      <div className="nav-item">
                        <Link className="ts-router-link " to="/">
                          <img
                            width="36"
                            src="/assets/icons/Icon-material-email.svg"
                            alt="envelope"
                          />
                        </Link>
                      </div>
                      <Dropdown
                        className="ts-user-details"
                        align="end"
                        title="user details"
                        id="userDetails"
                        onClick={() => {
                          setShowProfileMenu(true);
                        }}
                      >
                        <Dropdown.Toggle variant="transparent">
                          <span>{props.UserInfo.name}</span>
                          <img
                            width="33"
                            src="/assets/icons/Icon-material-account-circle.svg"
                            alt="envelope"
                          />
                        </Dropdown.Toggle>
                      </Dropdown>
                    </>
                  ) : (
                    <>
                      {location.pathname === "/login" ? (
                        <div className="nav-item">
                          <Link to="register">
                            <Button variant="primary-01 rounded-pill">
                              {props.rtlSwitch
                                ? pageData.register_ar
                                : pageData.register_en}
                            </Button>
                          </Link>
                        </div>
                      ) : (
                        <div className="nav-item">
                          <Link to="login">
                            <Button variant="primary-01 rounded-pill">
                              {props.rtlSwitch
                                ? pageData.login_ar
                                : pageData.login_en}
                            </Button>
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <ConfirmationModal
            show={modalShow}
            rtlSwitch={props.rtlSwitch}
            onHide={() => {
              setModalShow(false);
            }}
            onUserLogout={onUserLogout}
            confirmtitle={
              props.rtlSwitch
                ? pageData.confirm_logout.title_ar
                : pageData.confirm_logout.title_en
            }
            confirmdesc={
              props.rtlSwitch
                ? pageData.confirm_logout.desc_ar
                : pageData.confirm_logout.desc_en
            }
          />

          <ProfileMenuModal
            username={props.UserInfo ? props.UserInfo.name : ""}
            useremail={props.UserInfo ? props.UserInfo.email : ""}
            rtlSwitch={props.rtlSwitch}
            show={showProfileMenu}
            onHide={() => {
              setShowProfileMenu(false);
            }}
            onLogout={() => {
              setShowProfileMenu(false);
              setModalShow(true);
            }}
          />
        </>
      )}
    </div>
  );
}
