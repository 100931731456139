import "./Footer.scss";
import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";

import Stack from "react-bootstrap/Stack";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

// Images
import logo from "../../assets/icons/white-Arabic.svg";
import facebook from "../../assets/icons/facebook.svg";
import twitter from "../../assets/icons/Twitter.svg";
import pinterest from "../../assets/icons/Pinterest.svg";
import instagram from "../../assets/icons/Instagram.svg";
import google from "../../assets/icons/Google.svg";
import linkedin from "../../assets/icons/LinkedIn.svg";
import ExternalLinks from "../../assets/icons/Icon-feather-external-link.svg";

import WhatsappSvg from "components/Svgs/WhatsappSvg";
import GoogleSvg from "components/Svgs/GoogleSvg";

// data
import localData from "data/data.json";
let pageData = localData.footer;

export default function Footer(props) {
  const [data, setData] = useState([]);

  const { onProgramDetailsPage } = props;

  useEffect(() => {
    axios
      .get("footer")
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  }, []);

  return (
    <React.Fragment>
      {onProgramDetailsPage ? (
        <footer
          className={`footer ${props.rtlSwitch ? "rtl-footer" : ""}`}
          data-testid="Footer"
        >
          <Container>
            <div className="footer__main">
              <div className="footer__logo">
                {/* <img src={logo} alt="reham logo" /> */}
              </div>
              <ul className="footer__list">
                <li className="footer-links__title">
                  <img src={ExternalLinks} alt="external links" />{" "}
                  {props.rtlSwitch
                    ? pageData.useful_links_ar
                    : pageData.useful_links_en}
                </li>

                {props.isLogged ? (
                  <li>
                    {/* <Link className="footer__link" to="/my-programs">
             {props.rtlSwitch ? pageData.login_ar : pageData.login_en}
           </Link> */}
                    <Link className="footer__link" to="/login">
                      {props.rtlSwitch ? pageData.login_ar : pageData.login_en}
                    </Link>
                  </li>
                ) : (
                  <>
                    <li>
                      <Link className="footer__link" to="/login">
                        {props.rtlSwitch
                          ? pageData.login_ar
                          : pageData.login_en}
                      </Link>
                    </li>
                    <li>
                      <Link className="footer__link" to="/register">
                        {props.rtlSwitch
                          ? pageData.register_for_free_content_ar
                          : pageData.register_for_free_content_en}
                      </Link>
                    </li>
                  </>
                )}
              </ul>
              {/* <ul className="footer__list">
       <li className="footer-links__title">
         {props.rtlSwitch ? pageData.about_us_ar : pageData.about_us_en}
       </li>
       <li>
         <Link className="footer__link" to="/about">
           {props.rtlSwitch ? pageData.about_ar : pageData.about_en}
         </Link>
       </li>
       <li>
         <Link className="footer__link" to="/faq">
           {props.rtlSwitch ? pageData.faq_ar : pageData.faq_en}
         </Link>
       </li>
       <li>
         <Link className="footer__link" to="/legal-faq">
           {props.rtlSwitch ? pageData.legal_ar : pageData.legal_en}
         </Link>
       </li>
     </ul> */}
              <ul className="footer__list">
                <li className="footer-links__title">
                  {props.rtlSwitch ? pageData.contact_ar : pageData.contact_en}
                </li>
                <li>
                  <a
                    className="footer__link"
                    href={"mailto:" + data?.footer_contact_email}
                  >
                    {data?.footer_contact_email}
                  </a>
                </li>
                <li>
                  <a
                    className="footer__link"
                    href={"tel:" + data?.footer_contact_phone_no}
                  >
                    {data?.footer_contact_phone_no}
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__footer text-center ">
              <Stack
                direction="horizontal"
                gap={3}
                className={`flex-wrap justify-content-center mb-3 ${
                  props.rtlSwitch ? "flex-row-reverse" : ""
                }`}
              >
                {/* <a href={data?.footer_facebook_url}>
         <img
           className="footer__icon"
           src={facebook}
           alt="facebook icon"
         />
       </a>
       <a href={data?.footer_twitter_url}>
         <img className="footer__icon" src={twitter} alt="twitter icon" />
       </a>
       <a href={data?.footer_pinterest_url}>
         <img
           className="footer__icon"
           src={pinterest}
           alt="pinterest icon"
         />
       </a>
       <a href={data?.footer_intagram_url}>
         <img
           className="footer__icon"
           src={instagram}
           alt="instagram icon"
         />
       </a> */}
                {/* <a href={data?.footer_google_plus_url}>
         <img className="footer__icon" src={google} alt="google icon" />
       </a> */}
                {/* <a href={data?.footer_linkedin_url}>
         <img
           className="footer__icon"
           src={linkedin}
           alt="linkedin icon"
         />
       </a> */}
                <a
                  href="https://wa.me/96550406406"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <WhatsappSvg />
                </a>
                <a
                  href="mailto:hello@reham.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <GoogleSvg />
                </a>
                <p className="mb-0 fw-bold">
                  {props.rtlSwitch
                    ? pageData.language_ar
                    : pageData.language_en}
                </p>
              </Stack>

              <p className="fw-bold ">
                Reham &copy; 2022 &nbsp;
                <a
                  className="text-decoration-none text-white"
                  href="https://www.mukkancom.co/"
                  target="blank"
                >
                  Powered by Mukkancom
                </a>
              </p>
            </div>
          </Container>
        </footer>
      ) : (
        <footer
          className={`footer ${props.rtlSwitch ? "rtl-footer" : ""}`}
          data-testid="Footer"
        >
          <Container>
            <div className="footer__main">
              <div className="footer__logo">
                <img src={logo} alt="reham logo" />
              </div>
              <ul className="footer__list">
                <li className="footer-links__title">
                  <img src={ExternalLinks} alt="external links" />{" "}
                  {props.rtlSwitch
                    ? pageData.useful_links_ar
                    : pageData.useful_links_en}
                </li>

                {props.isLogged ? (
                  <li>
                    <Link className="footer__link" to="/my-programs">
                      {props.rtlSwitch
                        ? pageData.programs_ar
                        : pageData.programs_en}
                    </Link>
                  </li>
                ) : (
                  <>
                    <li>
                      <Link className="footer__link" to="/login">
                        {props.rtlSwitch
                          ? pageData.login_ar
                          : pageData.login_en}
                      </Link>
                    </li>
                    <li>
                      <Link className="footer__link" to="/register">
                        {props.rtlSwitch
                          ? pageData.register_for_free_content_ar
                          : pageData.register_for_free_content_en}
                      </Link>
                    </li>
                  </>
                )}
              </ul>
              <ul className="footer__list">
                <li className="footer-links__title">
                  {props.rtlSwitch
                    ? pageData.about_us_ar
                    : pageData.about_us_en}
                </li>
                <li>
                  <Link className="footer__link" to="/about">
                    {props.rtlSwitch ? pageData.about_ar : pageData.about_en}
                  </Link>
                </li>
                <li>
                  <Link className="footer__link" to="/faq">
                    {props.rtlSwitch ? pageData.faq_ar : pageData.faq_en}
                  </Link>
                </li>
                <li>
                  <Link className="footer__link" to="/legal-faq">
                    {props.rtlSwitch ? pageData.legal_ar : pageData.legal_en}
                  </Link>
                </li>
              </ul>
              <ul className="footer__list">
                <li className="footer-links__title">
                  {props.rtlSwitch ? pageData.contact_ar : pageData.contact_en}
                </li>
                <li>
                  <a
                    className="footer__link"
                    href={"mailto:" + data?.footer_contact_email}
                  >
                    {data?.footer_contact_email}
                  </a>
                </li>
                <li>
                  <a
                    className="footer__link"
                    href={"tel:" + data?.footer_contact_phone_no}
                  >
                    {data?.footer_contact_phone_no}
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__footer text-center ">
              <Stack
                direction="horizontal"
                gap={3}
                className={`flex-wrap justify-content-center mb-3 ${
                  props.rtlSwitch ? "flex-row-reverse" : ""
                }`}
              >
                <a href={data?.footer_facebook_url}>
                  <img
                    className="footer__icon"
                    src={facebook}
                    alt="facebook icon"
                  />
                </a>
                <a href={data?.footer_twitter_url}>
                  <img
                    className="footer__icon"
                    src={twitter}
                    alt="twitter icon"
                  />
                </a>
                <a href={data?.footer_pinterest_url}>
                  <img
                    className="footer__icon"
                    src={pinterest}
                    alt="pinterest icon"
                  />
                </a>
                <a href={data?.footer_intagram_url}>
                  <img
                    className="footer__icon"
                    src={instagram}
                    alt="instagram icon"
                  />
                </a>
                <a href={data?.footer_google_plus_url}>
                  <img
                    className="footer__icon"
                    src={google}
                    alt="google icon"
                  />
                </a>
                <a href={data?.footer_linkedin_url}>
                  <img
                    className="footer__icon"
                    src={linkedin}
                    alt="linkedin icon"
                  />
                </a>
                <p className="mb-0 fw-bold">
                  {props.rtlSwitch
                    ? pageData.language_ar
                    : pageData.language_en}
                </p>
              </Stack>

              <p className="fw-bold ">
                Reham &copy; 2022 &nbsp;
                <a
                  className="text-decoration-none text-white"
                  href="https://www.mukkancom.co/"
                  target="blank"
                >
                  Powered by Mukkancom
                </a>
              </p>
            </div>
          </Container>
        </footer>
      )}
    </React.Fragment>
  );
}

// Footer.propTypes = {};

// Footer.defaultProps = {};

// export default Footer;
